
@font-face {
  font-family: "CircularXX-Book";
  src: local('CircularXX-Book'), local('CircularXX-Book'),
  url("./assets/fonts/CircularXXWeb-Book.woff") format("woff");
}

@font-face {
  font-family: "CircularXX-Book";
  src: url("./assets/fonts/CircularXXWeb-Book.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXX-Bold";
  src: url("./assets/fonts/CircularXXWeb-Bold.woff") format("woff");
}

@font-face {
  font-family: "CircularXX-Bold";
  src: url("./assets/fonts/CircularXXWeb-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXX-Black";
  src: url("./assets/fonts/CircularXXWeb-Black.woff") format("woff");
}

@font-face {
  font-family: "CircularXX-Black";
  src: url("./assets/fonts/CircularXXWeb-Black.woff2") format("woff2");
}


body {
  margin: 0;
  padding: 0;
}

*{
  transition: font-size, margin 0.3s ease;
}
